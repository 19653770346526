import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const ManualPage = () => (
  <Layout>
    <Seo title="Manual" />
    <p>
      <Link to="/">Home</Link>
    </p>
    <h2>Manual</h2>
    Shooters Diary 2 (SD2)
    <p>
      <Link to="/">Go back</Link>
    </p>
  </Layout>
)

export default ManualPage
